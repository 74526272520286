import React from "react";
import Footer from "../components/Footer";
// import Carousel from "../components/Carousel";
import CourseIntroduction from "../components/CourseIntroduction";
import Feedback from "../components/Feedback";
import Productions from "../components/Productions";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";

//check ip

import { useState, useEffect } from "react";

const Home = () => {
  const [volume, setVolume] = useState(0);

  return (
    <div>
      <div className="px-2 lg:px-0 mx-auto mt-20 max-w-[1000px]  ">
        {/* <div className="max-w-[500px] mx-auto">
          <Carousel />
        </div> */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, type: "linear", duration: 2 }}
          className="player-wrapper w-full"
        >
          <ReactPlayer
            loop={true}
            url="https://firebasestorage.googleapis.com/v0/b/minh-hien-web.appspot.com/o/Video_Introduction%2F90279E2E-D8F7-4E6D-AB4D-4A02E93C.mp4?alt=media&token=2dbe5af8-fd9f-4e9e-9c4f-9a2bb75b282f"
            className="react-player"
            playing={true}
            playsinline={true}
            volume={volume}
            width="100%"
            height="100%"
            controls
          />
        </motion.div>
        <Productions />
        <CourseIntroduction />
        <Feedback />
      </div>
    </div>
  );
};

export default Home;
