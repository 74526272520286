import React from "react";

import { AiOutlineSearch } from "react-icons/ai";
import { BsSunFill } from "react-icons/bs";
import MenuToggle from "./MenuToggle";
import AvatarComp from "./Avatar";
import { BsFacebook } from "react-icons/bs";
import { SiTiktok, SiZalo } from "react-icons/si";
import { useAuthContext } from "../hooks/useAuthContext";

import { AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { motion, useCycle } from "framer-motion";
import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useEffect, useState } from "react";

function Navbar() {
  const [currentPage, setCurrentPage] = useState(null);
  const userNavItems = [
    { name: "Trang chủ", to: "" },
    { name: "Sản phẩm", to: "products/all" },
    { name: "Khoá học", to: "course" },
    { name: "Blog", to: "blogs" },
    // { name: "About me", to: "about" },
    { name: "Đăng nhập", to: "login" },
  ];

  const [isOpen, toggleOpen] = useCycle(false, true);
  const [conditionalNavItems, setconditionalNavItems] = useState(userNavItems);

  const { user, authIsReady } = useAuthContext();
  const { logout } = useLogout();

  //log user out
  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if (user) {
      if (user.email === "hiconcon.nuce@gmail.com") {
        const adminNavItems = [
          { name: "Trang chủ", to: "" },
          { name: "Sản phẩm", to: "products/all" },
          { name: "Khoá học", to: "course" },
          { name: "Blog", to: "blogs" },
          { name: "Quản lý sản phẩm", to: "product-managing" },
          { name: "Admin", to: "admin" },
          // { name: "About me", to: "about" },
          // { name: "Đăng nhập", to: "login" },
        ];
        setconditionalNavItems(adminNavItems);
      } else {
        setconditionalNavItems(
          userNavItems.filter(
            (navItem) => navItem.to !== "login" && navItem.to !== "signup"
          )
        );
      }
    } else {
      setconditionalNavItems(userNavItems);
    }
  }, [user]);
  return (
    <div className="bg-dark text-light3 box-border">
      {authIsReady && (
        <nav className="  text-headline5 p-2 text-dark  bg-dark z-50 backdrop-blur-lg bg-opacity-10 fixed top-0 left-0 right-0 items-center ">
          <div className="flex justify-between max-w-[1200px] mx-auto">
            <div className="flex  items-center gap-5">
              {" "}
              <Link to="/">
                <p className="font-600 text-dark">Minh Hien Accessories</p>
              </Link>
              <ul className="  h-fit hidden lg:flex  gap-5 text-body1 font-500 items-center">
                {conditionalNavItems &&
                  conditionalNavItems.map((item, idx) => (
                    <li
                      key={idx}
                      className={`text-xl ${
                        currentPage == item.to ? "font-700" : ""
                      } text-white pr-2 `}
                    >
                      <Link
                        onClick={() => {
                          setCurrentPage(item.to);
                        }}
                        to={item.to}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="flex items-center gap-2">
              <div>
                {user && (
                  <AvatarComp
                    onLogout={handleLogout}
                    displayName={user.displayName}
                    email={user.email}
                    imgUrl={user.photoURL}
                  />
                )}
              </div>
              <div className="flex items-center lg:hidden">
                <MenuToggle isOpen={isOpen} toggleOpen={toggleOpen} />
              </div>
              <motion.div
                initial={false}
                animate={isOpen ? "open" : "closed"}
                variants={{
                  open: (height = 1000) => ({
                    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
                    opacity: 1,
                    transition: {
                      type: "spring",
                      stiffness: 10,
                      restDelta: 2,
                    },
                  }),
                  closed: {
                    clipPath: "circle(30px at 200px 0px)",
                    opacity: 0,
                    transition: {
                      type: "spring",
                      stiffness: 200,
                      damping: 40,
                    },
                  },
                }}
                className="absolute top-0 bg-light text-dark border border-light3 right-0 w-1/2 z-50 h-fit rounded-bl-lg"
              >
                <ul className="mt-10 ml-5 font-500 flex flex-col items-start lg:hidden gap-5 text-body1 justify-center">
                  {conditionalNavItems &&
                    conditionalNavItems.map((item, idx) => (
                      <li key={idx} className="">
                        <Link
                          className="font-600 text-left"
                          onClick={toggleOpen}
                          to={item.to}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}

                  <div className="flex flex-wrap justify-start gap-5 text-headline6 mb-6">
                    <a
                      href="https://www.facebook.com/handmade.by.minhhien95"
                      target="_blank"
                      type="button"
                    >
                      <BsFacebook />
                    </a>

                    {/* <a
                      href="https://www.tiktok.com/@baopowerpoint?is_from_webapp=1&sender_device=pc"
                      target="_blank"
                      type="button"
                    >
                      <SiTiktok />
                    </a> */}

                    <a
                      href="https://www.instagram.com/minhhien_accessories/"
                      target="_blank"
                      type="button"
                    >
                      <AiFillInstagram />
                    </a>

                    <a
                      href="https://zalo.me/0964608302"
                      target="_blank"
                      type="button"
                    >
                      <SiZalo />
                    </a>
                  </div>
                </ul>
              </motion.div>
            </div>
          </div>
        </nav>
      )}
      {/* {!authIsReady && (
        <Spinner
          aria-label="Extra large spinner example"
          className="absolute top-1/2 left-1/2 -m-5 "
          size="xl"
        />
      )} */}
    </div>
  );
}

export default Navbar;
