import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useMotion } from "../motion/useMotion";
const CourseIntroduction = () => {
  const { textMotion } = useMotion();
  return (
    <motion.div
      variants={textMotion(2.3, 1.5, "linear")}
      initial="hidden"
      animate="visible"
      className="bg-light rounded-lg py-5 mt-5"
    >
      {" "}
      <motion.div
        variants={textMotion(2.3, 1.5, "linear")}
        initial="hidden"
        animate="visible"
      >
        <p className="text-center uppercase text-headline4 font-600  ">
          Khoá học
        </p>
        <p className="text-center">
          Tham gia khoá học thiết kế, tạo ra những sản phẩm đính kết
        </p>
        <Link to="course">
          <button className="bg-dark text-btn text-light font-400 px-5 py-1 rounded-sm block mx-auto my-5">
            Tìm hiểu thêm
          </button>
        </Link>
      </motion.div>
    </motion.div>
  );
};

export default CourseIntroduction;
