import React from "react";
import { useCycle } from "framer-motion";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import Gravatar from "react-gravatar";

function AvatarComp({ onLogout, displayName, email, imgUrl }) {
  const { user: currentUser } = useAuthContext();
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <div className="relative">
      <Gravatar
        id="avatarButton"
        type="button"
        email={currentUser.email}
        className="rounded-full border-2 border-dark3  w-[30px] h-[30px] mx-2 cursor-pointer"
        onClick={toggleOpen}
      />

      <div
        onClick={toggleOpen}
        className={`${
          isOpen ? "" : "hidden"
        } absolute top-0 right-0 mt-12  z-10 w-[150px]  rounded divide-y  shadow bg-light divide-light3`}
      >
        <div className="py-3 px-4 text-body2 text-dark">
          <div>{displayName}</div>
          <div className="font-medium truncate">{email}</div>
        </div>
        <ul
          className="py-1 text-body2 text-dark"
          aria-labelledby="avatarButton"
        >
          <li>
            <a href="#" className="block py-2 px-4 hover:bg-gray-600 ">
              Mục yêu thích
            </a>
          </li>
          <li>
            <Link to="course" className="block py-2 px-4 hover:bg-gray-600 ">
              Khóa học của tôi
            </Link>
          </li>
          <li>
            <Link to="/Profile" className="block py-2 px-4 hover:bg-gray-600 ">
              Cài đặt
            </Link>
          </li>
        </ul>
        <div className="py-1">
          <button
            onClick={onLogout}
            className="block py-2 px-4 text-body2 font-700 hover:bg-gray-600 text-red "
          >
            Đăng xuất
          </button>
        </div>
      </div>
    </div>
  );
}

export default AvatarComp;
