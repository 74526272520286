import React from "react";
import AdminTab from "../components/AdminTab";

const AdminPage = () => {
  return (
    <div className="mt-20">
      <AdminTab />
    </div>
  );
};

export default AdminPage;
