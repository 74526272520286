import React from "react";
import { motion } from "framer-motion";
import { useMotion } from "../motion/useMotion";
const CourseHeader = ({ isPurchased }) => {
  const { textMotion } = useMotion();
  return (
    <motion.div
      variants={textMotion(0.5, 1.5, "linear")}
      initial="hidden"
      animate="visible"
      className="p-12 h-[370px] text-center relative overflow-hidden bg-no-repeat bg-hero-pattern bg-cover rounded-lg"
    >
      <div
        className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
      >
        <div className="flex justify-center items-center h-full">
          <div className="text-light">
            <h2 className="font-600 uppercase text-headline5 mb-4">
              Khoá học đính kết của MINH HIEN ACCESSORIES
            </h2>
            <h4 className="font-semibold text-headline6 mb-6">
              Khởi nghiệp thật dễ dàng!
            </h4>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CourseHeader;
